@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @media screen and (min-width: 720px) {
    html {
      font-size: 16px;
    }
    * {
      user-select: none;
      cursor: default !important;
    }
  }

  #root {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0px;
    overflow: hidden;
    z-index: 10000002;
  }

  body {
    width: 100vw;
    height: 100vh;
    top: 0 !important;
    overflow: hidden;
    @apply bg-white;
  }

  h1 {
    font-family: Cabin;
    font-size: 70px;
    font-weight: 600;
    line-height: 75px;
    letter-spacing: 0.07em;
    text-align: center;
  }
  
  h4 {
    font-family: Cabin;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.07em;
    text-align: center;
  }
  
  h5 {
    font-family: Cabin;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.07em;
    text-align: center;
  }

  .text-wrapper {
    @apply p-5 rounded-2xl bg-white;
  }

  .text-wrapper > span {
    font-family: Cabin;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.07em;
    @apply block text-green;
  }

  .your-first-text {
    font-family: Jaldi;
    font-size: 70px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0.07em;
    text-align: center;
    @apply text-green-secondary;
  }

  .button-primary {
    font-family: Cabin;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    @apply text-green bg-white hover:bg-green-secondary rounded-full h-[52px] px-5 transition-all flex items-center;
  }
  
  .clickable-icon {
    @apply h-16 w-16 cursor-pointer p-3 rounded-full bg-white hover:bg-green-secondary transition-all fill-green-secondary hover:fill-green;
  }

  input {
    @apply w-full h-10 border-[5px] border-green rounded-md py-1 px-2;
  }

  input[type="radio"] {
    padding: 0px;
    -webkit-appearance: initial;
    appearance: initial;
    background: white;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    @apply w-8 h-8 bg-white !rounded-sm border-[5px] border-green;
  }
  
  input[type="radio"]:checked {
    @apply bg-green-secondary;
  }
  
  span.tag {
    display: flex;
    width: max-content;
    text-align: center;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    border-radius: 16px;
    cursor: pointer;
    user-select: none;
  }

  #google_translate_element {
    display: none !important;
  }
}